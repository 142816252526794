@font-face {
    font-family: "Helvetica Neue LT Std";
    src: url("HelveticaNeue-Bold.eot");
    src: url("HelveticaNeue-Bold.eot?#iefix") format("embedded-opentype"), url("HelveticaNeue-Bold.woff2") format("woff2"),
        url("HelveticaNeue-Bold.woff") format("woff"), url("HelveticaNeue-Bold.ttf") format("truetype"),
        url("HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica Neue LT Std";
    src: url("HelveticaNeue-Medium.eot");
    src: url("HelveticaNeue-Medium.eot?#iefix") format("embedded-opentype"), url("HelveticaNeue-Medium.woff2") format("woff2"),
        url("HelveticaNeue-Medium.woff") format("woff"), url("HelveticaNeue-Medium.ttf") format("truetype"),
        url("HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica";
    src: url("Helvetica-Light.eot");
    src: url("Helvetica-Light.eot?#iefix") format("embedded-opentype"), url("Helvetica-Light.woff2") format("woff2"),
        url("Helvetica-Light.woff") format("woff"), url("Helvetica-Light.ttf") format("truetype"),
        url("Helvetica-Light.svg#Helvetica-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica";
    src: url("Helvetica.eot");
    src: url("Helvetica.eot?#iefix") format("embedded-opentype"), url("Helvetica.woff2") format("woff2"),
        url("Helvetica.woff") format("woff"), url("Helvetica.ttf") format("truetype"), url("Helvetica.svg#Helvetica") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica";
    src: url("Helvetica-Bold.eot");
    src: url("Helvetica-Bold.eot?#iefix") format("embedded-opentype"), url("Helvetica-Bold.woff2") format("woff2"),
        url("Helvetica-Bold.woff") format("woff"), url("Helvetica-Bold.ttf") format("truetype"),
        url("Helvetica-Bold.svg#Helvetica-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
