@import "./assets/styles/custom.scss";

body {
    background-color: $background-color;
    color: $text-color;
    font-size: 16px;
    font-family: $fontHelvetica;
    overflow: visible !important;

    @include scroll-style(
        $size: var(--scroll-size, 4px),
        $thumb: var(--scroll-thumb, none),
        $thumb-color: var(--scroll-thumb-color, #808080),
        $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
        $track-color: var(--scroll-track, #ffffff),
        $track-radius: var(--scroll-track-radius, var(--scroll-radius))
    );

    img,
    svg {
        vertical-align: unset;
    }

    &.modal-open {
        padding-right: 4px !important;

        @include scroll-style(
            $size: var(--scroll-size, 4px),
            $thumb: var(--scroll-thumb, none),
            $thumb-color: var(--scroll-thumb-color, transparent),
            $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
            $track-color: var(--scroll-track, transparent),
            $track-radius: var(--scroll-track-radius, var(--scroll-radius))
        );
    }
}

.mainContainer {
    padding-top: 0px;
}

.siteLogo {
    height: 50px;

    @include mq("768px", min) {
        height: 89px;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $white;
    border-radius: 10px;
    padding: 10px 20px;
    @include font($fontHelveticaNeue, 18px, 1, 500);

    @include mq("desktop-wide", min) {
        border-radius: 16px;
        padding: 15px 30px;
    }

    &-primary {
        &:hover {
            background-color: $white;
            color: $dark;
        }
    }

    &:focus,
    &:active,
    &:focus-within {
        outline: unset !important;
        box-shadow: unset !important;
    }
}

.form-control {
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 !important;
    padding: 15px 10px !important;
}

.homepage {
    background-color: $background-color;
}
// .filmDirectionPage {
//     background-color: #ffffff;
// }

.scroll {
    @include scroll-style(
        $size: var(--scroll-size, 4px),
        $thumb: var(--scroll-thumb, none),
        $thumb-color: var(--scroll-thumb-color, #808080),
        $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
        $track-color: var(--scroll-track, transparent),
        $track-radius: var(--scroll-track-radius, var(--scroll-radius))
    );

    // Ejemplo 3
    &-ul {
        --scroll-size: 2px;
        --scroll-radius: 4px;
        --scroll-track: rgb(255 255 255 / 5%);
        --scroll-thumb-color: #ffffff80;
    }
}

.container {
    @include mq("767px", max) {
        // width: 100% !important;
        // max-width: 100% !important;
    }
}

.cardListing {
    @include scroll-style(
        $size: var(--scroll-size, 4px),
        $thumb: var(--scroll-thumb, none),
        $thumb-color: var(--scroll-thumb-color, #808080),
        $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),
        $track-color: var(--scroll-track, #15161a),
        $track-radius: var(--scroll-track-radius, var(--scroll-radius))
    );
}

.mouseContainerBox {
    overflow: hidden;
    position: relative;

    &:not(.buttonBox) {
        height: 100vh;
    }

    @media only screen and (max-width: 767px) {
        min-height: unset;
        height: auto;

        &:not(.buttonBox) {
            min-height: unset;
            height: auto;
        }
    }
}

.marquee-container {
    &.linerBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        flex-direction: column !important;
        z-index: -1;
        opacity: 0.3;
        overflow: hidden;

        .marquee {
            background-position: center;
            background-size: cover;
            animation-name: marqueeTop !important;
            // height: 100vh;
            /* background-attachment: fixed; */

            @keyframes marqueeTop {
                0% {
                    transform: translateY(-100%);
                }
                100% {
                    transform: translateY(0%);
                }
            }
        }

        .linerImgBlock {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            img {
                object-fit: contain;

                max-width: 1200px;
                margin: auto;
                width: 100%;
            }
        }
    }
}

.parallax {
    &.linerBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        flex-direction: column !important;
        z-index: -1;
        opacity: 0.3;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
    }
}

.parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}

.parallax span {
    display: block;
    margin-right: 30px;
    color: white;
}

.yarl__thumbnails_container {
    background-color: #15161acc !important;
}

.disable{
    pointer-events: none;
    opacity: 60%;
}