@use "../fonts/drukWide/drukwide-stylesheet.css";
@use "../fonts/helvetica/stylesheet.css";

$fontDrukWide: "Druk Wide";
$fontHelvetica: "Helvetica";
$fontHelveticaNeue: "Helvetica Neue LT Std";

.fontDrukWide {
    font-family: #{$fontDrukWide} !important;
}

.fontHelvetica {
    font-family: #{$fontHelvetica} !important;
}
.fontHelveticaNeue {
    font-family: #{$fontHelveticaNeue} !important;
}
