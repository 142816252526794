// colors
$white: #ffffff;
$dark: #15161a;
$background-color: $dark;
$text-color: $white;

//Main button
$btn-width: 120px;
$btn-height: 40px;

// Spacing
$block-padding: 60px;

$theme-colors: (
    "dark": $background-color,
    "primary": $background-color
);
